.navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
  }
  .navigation-menu {
    margin-left: auto;
  }
  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
  }
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #210D05;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    right: 1px;
    visibility: hidden;
  }
  .hamburger:hover {
    background-color: black;
  }
  @media screen and (max-width: 820px) {
  .navTitle{
    display: none;
  }
  }
  @media screen and (max-width: 550px) {
  .hamburger {
    visibility: visible;
  }
  .navTitle{
    display: none;
  }
  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 44px;
    left: 0;
    width: 100%;
    background-color: #171716;
    transition: background-color 0.2s ease-in-out;
    z-index: 100;
    display: none;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: #AFAEAC;
    width: 100%;
    padding: 1rem 0;
  }
  .navigation-menu li:hover {
    background-color: #3e3e3b;
  }  
  .navigation-menu.expanded ul {
    display: block;
  }
}
  